import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/LifeSkill2023/firstaiddemo/1.jpg';
import p2 from 'assests/Photos/LifeSkill2023/firstaiddemo/2.jpg';
import p3 from 'assests/Photos/LifeSkill2023/firstaiddemo/3.jpg';
import p4 from 'assests/Photos/LifeSkill2023/firstaiddemo/4.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2023 from 'views/LifeSkill2023/Sidebar2023';

const FirstaidDemo = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      BASIC LIFE SUPPORT(BLS), HEALTH AND HYGIENE AND FIRST AID
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 10  &  Date: 5 OCTOBER 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        A workshop on “Basic Life Support (BLS), Health and Hygiene, and First Aid” was organized for class 10 students on Thursday, 05 October 2023. The session was facilitated by a team of expert doctors from People Tree Hospital, Yeshwanthpur, Bengaluru. 
        <br></br>
        The workshop focused on first aid, call for help, and performing first aid with limited equipments. Live demonstrations of the situations in CPR (Cardiopulmonary Resuscitation) and how to perform it when the person is unconscious or has breathing casualties were explained by the doctors, with hands-on practice by the students. The students were also trained to perform first aid under common conditions like cuts, bites, burns or scars, and animal bites. A quiz was conducted and the students participated enthusiastically. This workshop helped the students understand the importance of first aid and its importance in dire situations.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
“First aid is not a choice, it’s a responsibility.”

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2023 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default FirstaidDemo;